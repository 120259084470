import React from "react"
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaGooglePlus,
} from "react-icons/fa"

const Footer = () => {
  return (
    <footer className="footer-builder">
      <div className="content">
        <div className="d-flex contact">
          <div className="d-flex flex-column">
            <div>Phone</div>
            <div>+32 50 31 28 32</div>
          </div>
          <div className="d-flex flex-column ml-address">
            <div>Address</div>
            <div>491 Merlin Crest Suite 963</div>
          </div>
        </div>

        <div className="row mt-30">
          <div className="col-sm-6 col-lg-3">
            <div className="widget">
              <h4 className="widget-title">Service</h4>
              <ul className="widget-list">
                <li>
                  <a href="#">Strategy Design</a>
                </li>
                <li>
                  <a href="#">Product Design</a>
                </li>
                <li>
                  <a href="#">Content Strategy</a>
                </li>
                <li>
                  <a href="#">Brand Strategy</a>
                </li>
                <li>
                  <a href="#">Development</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="widget">
              <h4 className="widget-title">Help And Advice</h4>
              <ul className="widget-list">
                <li>
                  <a href="#">How it works</a>
                </li>
                <li>
                  <a href="#">Contact Support</a>
                </li>
                <li>
                  <a href="#">Privacy Policy</a>
                </li>
                <li>
                  <a href="#">FAQ</a>
                </li>
                <li>
                  <a href="#">Development</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-6 col-lg-2">
            <div className="widget">
              <h4 className="widget-title">Company</h4>
              <ul className="widget-list">
                <li>
                  <a href="#">About</a>
                </li>
                <li>
                  <a href="#">Blog</a>
                </li>
                <li>
                  <a href="#">Content Strategy</a>
                </li>
                <li>
                  <a href="#">Contact</a>
                </li>
                <li>
                  <a href="#">Jobs</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="widget getInTouch">
              <h4 className="widget-title">Get in touch</h4>
              <div>Feel free to get in touch with us vai email</div>
              <div className="email">equiries@birdyhomes.com.Account</div>
              <div className="d-flex">
                <div className="social-icon">
                  <FaFacebookF />
                </div>
                <div className="social-icon">
                  <FaTwitter />
                </div>
                <div className="social-icon">
                  <FaGooglePlus />
                </div>
                <div className="social-icon">
                  <FaLinkedinIn />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
