import React from "react"
import PropTypes from "prop-types"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation } from "swiper/core"

SwiperCore.use([Navigation])
const SwiperBoard = ({ navClass }) => {
  const params = {
    spaceBetween: 10,
    watchSlidesVisibility: true,
    navigation: {
      prevEl: `.swiper-prev-${navClass}`,
      nextEl: `.swiper-next-${navClass}`,
    },
    breakpoints: {
      1300: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 1,
      },
      0: {
        slidesPerView: 1,
      },
    },
  }

  return (
    <div className="swiper-list-wrap big-swiper pb-4">
      <div className="swiper-list-inner">
        <Swiper {...params}>
          <SwiperSlide>
            <a href="#" className="product-items">
              <div className="board-item">
                <div className="board-item-left">
                  <img src="/img/img-01.jpg"></img>
                  <div className="board-item-left-txt">
                    <h3>Design Favs</h3>
                    <span>34 PINS</span>
                  </div>
                </div>
                <div className="board-item-right">
                  <div className="board-item-right-img">
                    <img src="/img/img-02.jpg"></img>
                  </div>
                  <div className="board-item-right-img">
                    <img src="/img/img-03.jpg"></img>
                  </div>
                </div>
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="#" className="product-items">
              <div className="board-item">
                <div className="board-item-left">
                  <img src="/img/img-01.jpg"></img>
                  <div className="board-item-left-txt">
                    <h3>Design Favs</h3>
                    <span>34 PINS</span>
                  </div>
                </div>
                <div className="board-item-right">
                  <div className="board-item-right-img">
                    <img src="/img/img-02.jpg"></img>
                  </div>
                  <div className="board-item-right-img">
                    <img src="/img/img-03.jpg"></img>
                  </div>
                </div>
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="#" className="product-items">
              <div className="board-item">
                <div className="board-item-left">
                  <img src="/img/img-04.jpg"></img>
                  <div className="board-item-left-txt">
                    <h3>Outdoor Vibes</h3>
                    <span>34 PINS</span>
                  </div>
                </div>
                <div className="board-item-right">
                  <div className="board-item-right-img">
                    <img src="/img/img-05.jpg"></img>
                  </div>
                  <div className="board-item-right-img">
                    <img src="/img/img-06.jpg"></img>
                  </div>
                </div>
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="#" className="product-items">
              <div className="board-item">
                <div className="board-item-left">
                  <img src="/img/img-07.jpg"></img>
                  <div className="board-item-left-txt">
                    <h3>Design Favs</h3>
                    <span>34 PINS</span>
                  </div>
                </div>
                <div className="board-item-right">
                  <div className="board-item-right-img">
                    <img src="/img/img-08.jpg"></img>
                  </div>
                  <div className="board-item-right-img">
                    <img src="/img/img-09.jpg"></img>
                  </div>
                </div>
              </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="#" className="product-items">
              <div className="board-item">
                <div className="board-item-left">
                  <img src="/img/img-01.jpg"></img>
                  <div className="board-item-left-txt">
                    <h3>Dream Livings</h3>
                    <span>34 PINS</span>
                  </div>
                </div>
                <div className="board-item-right">
                  <div className="board-item-right-img">
                    <img src="/img/img-04.jpg"></img>
                  </div>
                  <div className="board-item-right-img">
                    <img src="/img/img-08.jpg"></img>
                  </div>
                </div>
              </div>
            </a>
          </SwiperSlide>
        </Swiper>
        <div className={`swiper-ct-prev swiper-prev-${navClass}`}></div>
        <div className={`swiper-ct-next swiper-next-${navClass}`}></div>
      </div>
    </div>
  )
}

SwiperBoard.propTypes = {
  navClass: PropTypes.string.isRequired,
}

export default SwiperBoard
