import React from "react"
import { handleSubscribe } from "../../services/acsubscribe"

class StayInTouch extends React.Component {
    state = {
        name: ``,
        email: ``,
    }
    handleUpdate = event => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }
    handleSubmit = event => {
        event.preventDefault()
        handleSubscribe(this.state)
    }
  render() {
    return (
      <>
        <div className="stay-in-touch mt-5">
          <div className="container">
            <h2 className="text-center mb-4">STAY IN TOUCH</h2>
            <div className="inner">
                <form className="subscribe-form"  method="post" onSubmit={event => {
                            this.handleSubmit(event)
                            }}
                >                    
              <div className="row gutters-10 align-items-end">
                <div className="col-md">
                  <div className="row gutters-10">
                    <div className="col-sm-6 mb-3 mb-sm-0">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="form-control"
                        placeholder="Name"
                        required onChange={this.handleUpdate}
                      />
                    </div>
                    <div className="col-sm-6 mb-3 mb-sm-0">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="form-control"
                        placeholder="Email*"
                        required onChange={this.handleUpdate}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-auto text-center mt-3 mt-md-0">
                  <button type="submit" className="btn btn-black btn-lg">SIGN UP</button>
                </div>
              </div>
              </form>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default StayInTouch
