import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import PropTypes from "prop-types"
import StayInTouch from "../../templates/Includes/StayInTouch"

const Subscribe = ({ customClass }) => {
  return (
    <>
      <StayInTouch />
    </>
  )
}

Subscribe.propTypes = {
  customClass: PropTypes.string,
}

Subscribe.defaultProps = {
  customClass: "",
}

export default Subscribe
