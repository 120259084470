import React from "react";
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper/core';

SwiperCore.use([Navigation]);
const SwiperQuotes = ({ navClass }) => {
  const params = {
    spaceBetween: 10,
    watchSlidesVisibility: true,
    navigation: {
      prevEl: `.swiper-prev-${navClass}`,
      nextEl: `.swiper-next-${navClass}`,
    },
    breakpoints: {
      1300: {
        slidesPerView: 4,
      },
      992: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 2,
      },
      0: {
        slidesPerView: 1,
      },
    },
  }

  return (
    <div className="swiper-list-wrap">
      <div className="swiper-list-inner">
        <Swiper {...params}>
          <SwiperSlide>
            <a href="#" className="product-items">
                <div className="product-items-thumb quotes-item">
                    <div className="quotes-inner">
                        <h3>Quote 1</h3>
                        <div className="quotes-inner-list">
                            <span>Summary</span>
                            <ul>
                                <li>Willowdale</li>
                                <li>Hamptons Style</li>
                                <li>4 Bedrooms</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="#" className="product-items">
                <div className="product-items-thumb quotes-item">
                    <div className="quotes-inner">
                        <h3>Quote 2</h3>
                        <div className="quotes-inner-list">
                            <span>Summary</span>
                            <ul>
                                <li>Willowdale</li>
                                <li>Hamptons Style</li>
                                <li>4 Bedrooms</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="#" className="product-items">
                <div className="product-items-thumb quotes-item">
                    <div className="quotes-inner">
                        <h3>Quote 3</h3>
                        <div className="quotes-inner-list">
                            <span>Summary</span>
                            <ul>
                                <li>Willowdale</li>
                                <li>Hamptons Style</li>
                                <li>4 Bedrooms</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="#" className="product-items">
                <div className="product-items-thumb quotes-item">
                    <div className="quotes-inner">
                        <h3>Quote 3</h3>
                        <div className="quotes-inner-list">
                            <span>Summary</span>
                            <ul>
                                <li>Willowdale</li>
                                <li>Hamptons Style</li>
                                <li>4 Bedrooms</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="#" className="product-items">
                <div className="product-items-thumb quotes-item">
                    <div className="quotes-inner">
                        <h3>Quote 3</h3>
                        <div className="quotes-inner-list">
                            <span>Summary</span>
                            <ul>
                                <li>Willowdale</li>
                                <li>Hamptons Style</li>
                                <li>4 Bedrooms</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </a>
          </SwiperSlide>
          
        </Swiper>
        <div className={`swiper-ct-prev swiper-prev-${navClass}`}></div>
        <div className={`swiper-ct-next swiper-next-${navClass}`}></div>
      </div>
    </div>
  )
}

SwiperQuotes.propTypes = {
  navClass: PropTypes.string.isRequired
};

export default SwiperQuotes
