import React, { useMemo, useState } from "react"
import { Modal } from "react-bootstrap"
import ShowMoreText from "react-show-more-text"
import { Swiper, SwiperSlide } from "swiper/react"
import config from "../../../config/config"
import { isLoggedIn, getUser } from "../../../services/auth"
import GroundFloorList from "../../Category/HomeDesign/GroundFloorList"
import Level1RoomsList from "../../Category/HomeDesign/Level1RoomsList"

const ModalHomeDesignDetail = ({ show, handleClose, item}) => {
  const [slideOption, setSlideOption] = useState(null)
  const modal = useMemo(() => {
    const swiper_params = {
      spaceBetween: 10,
      navigation: {
        prevEl: ".swiper-button-prev-unique",
        nextEl: ".swiper-button-next-unique",
      },
      breakpoints: {
        1400: {
          slidesPerView: 5,
        },
        1200: {
          slidesPerView: 4,
        },
        992: {
          slidesPerView: 3,
        },
        768: {
          slidesPerView: 2,
        },
        0: {
          slidesPerView: 1,
        },
      },
    }
    const host = config.host
    var isShowing = show
    const valid_status = ["Active"]
    var image_path
    var other_options = []
    var vt = null
    var link = null
    var lotSize = null
    var floorplans
    var twoImages = false   
    image_path = item.Image
    link = item.link
    floorplans = item.Floorplans
    item.formattedPrice = item.vt.rrp
    if (typeof floorplans !== undefined && floorplans) {
      var i = 0
      for (; floorplans[i];) {
        var option = floorplans[i].UndigitalFloorplan
        option.Image = option.groundFloorImagePath
        var isValid = valid_status.includes(option.status)
        if (isValid) {
          other_options.push(option)
        }
        i++
      }
    }  
    if(item.vt){            
        if(typeof item.vt === 'object'){
            if(item.vt.groundFloorImagePath){
                image_path = item.vt.groundFloorImagePath
                item.groundFloorImagePath = item.vt.groundFloorImagePath
            }
            if(item.vt.firstFloorImagePath){
                image_path = item.vt.firstFloorImagePath
                item.firstFloorImagePath = item.vt.firstFloorImagePath
            }
            if(item.vt.groundFloorImagePath && item.vt.firstFloorImagePath){
                twoImages = true
            }
            link = `${item.link}${item.vt ? `/?vid=${item.vt.objectID}` : ''}`
            lotSize = (item.vt.lotWidth && item.vt.lotLength) ? (item.vt.lotWidth + " x " + item.vt.lotLength + "m") : null
            item.lotSize = lotSize
            setSlideOption(item.vt)
        }else if(typeof item.vt === 'number'){
            link = `${item.link}/?vid=${item.vt}`
        }
    }
    
    //isDoubleStorey= false
    item.about = item.description
    item.twoImages = twoImages
    return <Modal
      show={isShowing}
      onHide={handleClose}
      onShow={resize}
      size={"xxl"}
      animation={true}
      className="itemModal full-height"
    >
      <div className="row mb-4">
        <div className="col-12">
          <div
            className="btn-close d-lg-block"
            onClick={handleClose}
          ></div>
          <div className="main-title-wrap">
            <div className="board-page-title">
              <h1 className="product-name-title text-uppercase h2">
                {item.name}
              </h1>
            </div>
          </div>
        </div>       
        <div className="col-12 col-lg-12">
          <div className="d-flex flex-row justify-content-between align-items-end board-page-title home-design-subtitle mt-1">
            <div>
              <span className="product-name-span">layout</span>
              <h3 className="product-name-title">{slideOption ? slideOption.name : item.name}</h3>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            {slideOption && (
            <div className="d-flex mx-n2 mb-2 mb-sm-0">
              <div className="mx-2 item-feature">
                {slideOption.bed}
                <svg width="26" height="26">
                  <use xlinkHref="/img/icons.svg#icon-bed-stroke"></use>
                </svg>
              </div>
              <div className="mx-2 item-feature">
                {slideOption.bath}
                <svg width="26" height="26">
                  <use xlinkHref="/img/icons.svg#icon-bath-stroke"></use>
                </svg>
              </div>
              <div className="mx-2 item-feature">
                {slideOption.living}
                <svg width="26" height="26">
                  <use xlinkHref="/img/icons.svg#icon-sofa-stroke"></use>
                </svg>
              </div>
              <div className="mx-2 item-feature">
                {slideOption.garage}
                <svg width="26" height="26">
                  <use xlinkHref="/img/icons.svg#icon-garages-stroke"></use>
                </svg>
              </div>
            </div>
            )}
          </div>
           <div className="wrap-section row mt-2 mt-md-4">
                <div className="col-12 col-md-3 d-none d-md-block">
                  {item.vt.GroundFloorRoomsAmenities ? (
                    <GroundFloorList
                      list={item.vt.GroundFloorRoomsAmenities}
                    />
                  ) : (
                    ""
                  )}
                  {item.vt.Level1RoomsAmenities ? (
                    <Level1RoomsList list={item.vt.Level1RoomsAmenities} />
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-12 col-md-9 position-relative">
                    {item.twoImages ? (
                        <div className="browser-img-wrap">
                            <div className="container">
                                <div className="row">
                                  {item.groundFloorImagePath && (
                                    <div className="col-6">
                                    <img
                                      src={item.groundFloorImagePath}
                                      alt={item.name}
                                      className="w-100"
                                    />
                                    </div>
                                  )}
                                  {item.firstFloorImagePath && (
                                    <div className="col-6">
                                    <img
                                      src={item.firstFloorImagePath}
                                      alt={item.name}
                                      className="w-100"
                                    />
                                    </div>
                                  )}  
                                </div>
                              </div>
                        </div>
                      ) : (
                        <div className="browser-img-wrap">
                          {item.groundFloorImagePath ? (
                            <img
                              className="mw-100"
                              src={item.groundFloorImagePath}
                              alt={item.name}
                            />
                          ):(
                        <>
                            {item.firstFloorImagePath && (
                                <img
                                className="mw-100"
                                src={item.firstFloorImagePath}
                                alt={item.name}
                              />
                              )}  
                         </>
                          )}
                        </div>
                      )}
                </div>
            </div>          
        </div>
      </div>
    </Modal>
  }, [show, handleClose, item, slideOption])

  function resize() {
    window.dispatchEvent(new Event("resize"))
  }

  return <>{modal}</>
}

export default ModalHomeDesignDetail
