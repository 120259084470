import React from "react"

const MyBoardItem = ({ name, subtitle, size }) => {
  const divSize = (100 - 3 * size) / size
  return (
    <div
      className="c-board-item"
      style={{
        width: `${divSize}%`,
        paddingTop: `${divSize}%`,
        background: "#E5E5E5",
      }}
    >
      <div className="c-board-item-content">
        <div className="c-board-item-name">{name}</div>
        <br />
        <div className="c-board-item-sub-title">{subtitle}</div>
      </div>
    </div>
  )
}

export default MyBoardItem
