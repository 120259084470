import React from "react"
import MyBoardItem from "./MyboardItem"
import Swiper from "react-id-swiper"

const MyBoards = props => {
  const data = {
    size: props.size,
    boards: [
      {
        id: "board-1",
        name: "JOS's MTV Crib",
        subtitle: "34 PINS",
      },
      {
        id: "board-2",
        name: "Board #2",
        subtitle: "11 PINS",
      },
      {
        id: "board-3",
        name: "Board #3",
        subtitle: "17 PINS",
      },
    ],
  }
  const params = {
    slidesPerView: 5,
    spaceBetween: 50,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      1920: {
        slidesPerView: 5,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 40,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
    },
  }

  if (data.size == 2) {
    return (
      <div className="c-boards">
        {data.boards.map(board => (
          <MyBoardItem
            key={board.id}
            name={board.name}
            subtitle={board.subtitle}
            size={data.size}
          ></MyBoardItem>
        ))}
      </div>
    )
  } else {
    return (
      <div className="swiper-list-wrap">
        <Swiper {...params}>
          {data.boards.map(board => (
            <MyBoardItem
              key={board.id}
              name={board.name}
              subtitle={board.subtitle}
              size={data.size}
            ></MyBoardItem>
          ))}
        </Swiper>
      </div>
    )
  }
}

export default MyBoards
