import React from "react"
import Swiper from "react-id-swiper"

const Level1RoomsList = ({list}) => {
  return (
    <div className="floor-detail">
      <p className="floor-detail-title">
        Upstairs
      </p>
      <ul className="custom-list">
      {list.map((item, idx) => (
        <li key={`Level1RoomsList${idx}`}>{item.UndigitalRoomAmenity.name}</li>
      ))
      }
      </ul>
    </div>
  )
}

export default Level1RoomsList
