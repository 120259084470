import React from "react"

import Layout from "../templates/Page"
import Footer from "../components/ContentBuilder/Footer"
import SwiperList from "../components/ContentBuilder/SwiperList"
import Header from "../components/ContentBuilder/Header"
import CustomDesign from "../components/ContentBuilder/SwiperList/CustomDesign"
import MyBoards from "../components/MyBoards"
import MyQuotes from "../components/MyQuotes"
import Subscribe from "../components/Footer/subscribe"
import SwiperBoard from "../components/ContentBuilder/SwiperList/SwiperBoard"
import SwiperQuotes from "../components/ContentBuilder/SwiperList/SwiperQuotes"

const MyBoardCategoryPage = () => {
  return (
    <Layout hideHeader>
      <Header isLogin />

      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/explore/">Explore</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              My Boards
            </li>
          </ol>
        </nav>

        <div className="account-avt-wrap">
          <div className="account-avt">
            <div className="account-avt-inner">
              <span>CA</span>
              <img src="/img/christop-3.png"></img>
            </div>
            <a href="#" className="account-avt-change">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19.5"
                height="19.5"
                viewBox="0 0 19.5 19.5"
              >
                <path
                  d="M0,0V18M9,9H-9"
                  transform="translate(9.75 0.75)"
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  stroke-width="1.5"
                />
              </svg>
            </a>
          </div>
          <p className="account-avt-name">Christian</p>
        </div>

        <div className="mt-30 mb-30 border-bottom-7px">
          <div className="row align-items-center">
            <div className="col-md">
              <h2 className="product-name-title d-flex align-items-center">
                My Boards
                <svg className="ml-4" width="43px" height="43px">
                  <use xlinkHref="/img/icons.svg#icon-circle-plus"></use>
                </svg>
              </h2>
            </div>
            <div className="col-md-auto">
              <button type="button" className="button-link d-none d-lg-block">
                View All
              </button>
            </div>
          </div>
        </div>
        <SwiperBoard navClass="board" />

        <div className="mt-30 mb-30 border-bottom-7px">
          <div className="row align-items-center">
            <div className="col-md">
              <h2 className="product-name-title d-flex align-items-center">
                My Quotes
                <svg className="ml-4" width="43px" height="43px">
                  <use xlinkHref="/img/icons.svg#icon-circle-plus"></use>
                </svg>
              </h2>
            </div>
            <div className="col-md-auto">
              <button type="button" className="button-link d-none d-lg-block">
                View All
              </button>
            </div>
          </div>
        </div>
        <SwiperQuotes navClass="quotes" />

        <div className="mt-30 mb-30 border-bottom-7px">
          <div className="row align-items-center">
            <div className="col-md">
              <h3 className="product-name-title d-flex align-items-center">
                Recently Viewed
              </h3>
            </div>
            <div className="col-md-auto">
              <button type="button" className="button-link d-none d-lg-block">
                View All
              </button>
            </div>
          </div>
        </div>
        <div className="row recent-view">
          <div className="col-6 col-md-4 col-lg-3 col-xl-2">
            <a href="#" className="recent-item">
              <img src="/img/img-01.jpg"></img>
            </a>
          </div>
          <div className="col-6 col-md-4 col-lg-3 col-xl-2">
            <a href="#" className="recent-item">
              <img src="/img/img-02.jpg"></img>
            </a>
          </div>
          <div className="col-6 col-md-4 col-lg-3 col-xl-2">
            <a href="#" className="recent-item">
              <img src="/img/img-03.jpg"></img>
            </a>
          </div>
          <div className="col-6 col-md-4 col-lg-3 col-xl-2">
            <a href="#" className="recent-item">
              <img src="/img/img-04.jpg"></img>
            </a>
          </div>
          <div className="col-6 col-md-4 col-lg-3 col-xl-2">
            <a href="#" className="recent-item">
              <img src="/img/img-01.jpg"></img>
            </a>
          </div>
          <div className="col-6 col-md-4 col-lg-3 col-xl-2">
            <a href="#" className="recent-item">
              <img src="/img/img-05.jpg"></img>
            </a>
          </div>
          <div className="col-6 col-md-4 col-lg-3 col-xl-2">
            <a href="#" className="recent-item">
              <img src="/img/img-06.jpg"></img>
            </a>
          </div>
        </div>
      </div>
      <Subscribe></Subscribe>
    </Layout>
  )
}

export default MyBoardCategoryPage
