import axios from "axios";
import config from '../config/config'

export const handleSubscribe = ({ name, email }) => {
    var data = {
        name: name,
        email: email,
    };
    axios.post(config.host + "/tribecaapi/ac_create_contact", data)
            .then(response => {
                let _response_data = response.data;
                if (_response_data === 0) {              
                }else{
                    
                }
                if(typeof window !== 'undefined'){
                    window.location.href = "/thank-you/";
                }
            }).catch(function (error) {
    });
}

export const handleSaveSubscribe = (data) => {
    axios.post(config.host + "/tribecaapi/createenquire", data)
            .then(response => {
                let _response_data = response.data;
                if (_response_data == 1) {
                    
                }else{
                    
                }
                if(typeof window !== 'undefined'){
                    window.location.href = "/thank-you/";
                }
            }).catch(function (error) {
    });
}