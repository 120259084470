import React from "react"

const GroundFloorList = ({list}) => {
  return (
    <div className="floor-detail">
      <p className="floor-detail-title">
        Downstairs
      </p>
      <ul className="custom-list">
      {list.map((item, idx) => (
        <li key={`GroundFloorList${idx}`}>{item.UndigitalRoomAmenity.name}</li>
        ))
      }
      </ul>
    </div>
  )
}

export default GroundFloorList
