import React, { useState, useEffect, useRef, useCallback } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation } from "swiper/core"
import ModalHomeDesignDetail from "../Modal/ModalHomeDesignDetail"
SwiperCore.use([Navigation])

const CustomDesign = ({ homedesign, variant, gallery }) => {
  const params = {
    slidesPerView: 2,
    spaceBetween: 10,
    // centerInsufficientSlides: true,
    navigation: {
      prevEl: ".swiper-button-prev-unique",
      nextEl: ".swiper-button-next-unique",
    },
    breakpoints: {
      992: {
        slidesPerView: 2,
      },
      0: {
        slidesPerView: 2,
      },
    },
  }

  const [data, setData] = useState(gallery)
  const [loaded, setLoaded] = useState(false)
  const [quickViewData, setQuickViewData] = useState(null)
  const imgElement = useRef(null)

  useEffect(() => {
    setData(gallery)
    homedesign.vt = variant
    setQuickViewData(homedesign)
  }, [gallery])
  
  const [showQuickView, setShowQuickView] = useState(false)
  
  
  return (
    <>
      <div className="dual-swiper-wrap">
        <Swiper {...params} className="dual-swiper">
          {data.length > 0 ? (
            data.map((item, i) => (
              <SwiperSlide key={`main-gallery${i}`}>
                <div
                  className={`dual-img ${item.UndigitalGalleryImage.imagePosition}`}
                >
                  <a
                    className="thumbnail-wrap-link"
                    data-width={
                      loaded && imgElement.current.src.slice(-3) === "svg"
                        ? imgElement.current.naturalWidth * 9
                        : ""
                    }
                    data-height={
                      loaded && imgElement.current.src.slice(-3) === "svg"
                        ? imgElement.current.naturalHeight * 9
                        : ""
                    }
                    href="#"
                     onClick={e => setShowQuickView(true)}
                    
                  >
                    <img
                      alt=""
                      src={item.UndigitalGalleryImage.imagePath}
                      className="pointer"
                      ref={imgElement}
                      onLoad={() => setLoaded(true)}
                    />
                  </a>
                </div>
              </SwiperSlide>
            ))
          ) : (
            <p className="loading-text">Loading...</p>
          )}
        </Swiper>
        <div className="swiper-button-prev-unique"></div>
        <div className="swiper-button-next-unique"></div>
      </div>
      {quickViewData !== null && (
        <ModalHomeDesignDetail
          show={showQuickView}
          handleClose={() => setShowQuickView(false)}
          item={quickViewData}
        />
      )}
    </>
  )
}

export default CustomDesign
